<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
.container-filter
  font-size: 12px
  font-weight: 600
  line-height: 1.7rem
  .link
    color: darken(#f8f9fa, 40%)
.container-search
  width: calc(100% - 300px)
  line-height: 1.7rem
  font-size: 12px
  .link
.container-inactive-top
  position: relative
  top: -6rem
  height: 6rem
  background-color: rgba(248,249,250,.7)
.required
  color: tomato
// .container-modal-backdrop
//   // background-color: rgba(248,249,250,.5)
//   background-color: rgba(0,0,0,.2)
//   position: fixed
//   top: 0px
//   left: 0px
//   width: 100%
//   height: 100%
//   z-index: 2000
// .container-modal
//   position: fixed
//   z-index: 2001
//   top: 3rem
//   width: 760px
//   left: calc(50% - 760px/2)
//   .close
//     font-size: 24px
//   .body
//   .text-description
//     color: darken(#f8f9fa, 50%)
//     font-size: 12px
//     font-weight: 600
.container-table
  font-size: 12px
  // overflow: scroll
  // position: relative
  // height: calc(100% - 300px)
  // height: 300px
  .sticky-header
    position: sticky
    top: -1px
    // left: 0px
    // display: inline-block
    background-color: #fff
    z-index: 1000
    // border-bottom: solid 2px #ccc
    outline: solid 1px #ccc
    border-bottom: 0
    // font-weight: 800
    color: #333 !important
  .link-view-out
    text-decoration: none
    &.router-link-active
      background-color: #f0f0f0
    .link-view
      color: #007bff
      border-bottom: solid 0.05em #007bff
      font-weight: bold
      transition: color linear 100ms, border linear 100ms
    &:hover
      .link-view
        color: #333
        border-color: lighten(#333, 30%)
        text-decoration: none
    &:visited
      .link-view
        color: #555
        border-bottom: solid 0.05em lighten(#555, 30%)
      &:hover
        .link-view
          color: #333
  // .link-view
  //   // text-decoration: underline
  //   color: #007bff
  //   border-bottom: solid 0.05em #007bff
  //   font-weight: bold
  //   transition: color linear 100ms, border linear 100ms
  //   &:hover
  //     color: #333
  //     border-color: lighten(#333, 30%)
  //     text-decoration: none
  //   &:visited
  //     color: #555
  //     border-bottom: solid 0.05em lighten(#555, 30%)
  //     &:hover
  //       color: #333
  tbody
    tr
      td
        color: #555
.link-excel
  display: none
  &.router-link-active
    display: inline
</style>
<template lang="pug">
.container-search-pad
  div.container-search.bg-white.shadow-sm.pl-2
    router-link.link-back(:to='`/property/${property_id}/activity`')
      span.text-dark(style='font-size: 18px; line-height: 50px;')
        b-icon-chevron-left
        strong(style='padding-left: 5px; font-size: 12px; line-height: 50px; color: #333') 모든 목록

    template(v-if='saved_filter.id && saved_filter.is_listed == "Y"')
      //- router-link.btn.btn-sm.btn-default.bg-white.ml-1.py-2(:to='`/property/${property_id}/customer/${document_id}/${$options.filters.encodeText(document.name)}`') {{document.name}} sdf
      router-link.link-hover(:to='`/property/${property_id}/customer/${document_id}/${$options.filters.encodeText(document.name)}`')
        strong.text-secondary.ml-4.ml-3 {{document.name}}
      router-link.btn.btn-sm.btn-light.text-primary.ml-2.py-2(:to='`/property/${property_id}/customer/${document_id}${document_filter_postfix}/${$options.filters.encodeText(document.name)}`' exact) {{saved_filter.name}}
    template(v-else-if='saved_filter_id && saved_filter.id')
      router-link.link-hover(:to='`/property/${property_id}/customer/${document_id}/${$options.filters.encodeText(document.name)}`')
        strong.text-secondary.ml-4.ml-3 {{document.name}}
      router-link.btn.btn-sm.btn-light.text-primary.ml-2.py-2(:to='`/property/${property_id}/customer/${document_id}${document_filter_postfix}/${$options.filters.encodeText(document.name)}`' exact) {{saved_filter.search_config.length}}개 필터
    template(v-else)
      router-link.link-hover(:to='`/property/${property_id}/customer/${document_id}/${$options.filters.encodeText(document.name)}`')
        strong.text-secondary.ml-4.ml-3 {{document.name}}
    //- button.btn.btn-light.px-1.py-1(style='border-left: 0 !important'): b-icon-star(style='color: #ccc' @click.prevent.stop='toggle_star()')
    template(v-if='!is_starred')
      a.btn.text-secondary.py-1.px-2.ml-2(href='#' @click.stop.prevent='toggle_star' style='font-size: 11px; opacity: 0.5')
        b-icon-star
    template(v-else)
      a.btn.text-warning.py-1.px-2.ml-2(href='#' @click.stop.prevent='toggle_star' style='font-size: 11px; opacity: 0.5; color: rgb(241, 196, 15)')
        b-icon-star-fill
    router-link.btn.btn-primary.btn-sm.shadow-sm.float-right(:to='`/property/${property_id}/customer/${document_id}/start-from-scratch`' style='font-size: 12px; font-weight: bolder; margin: 5px 15px 5px 5px; padding: 0.6rem 1rem') 추가

  .bg-white.shadow-sm.mb-2
    .p-3
      .mb-2
        router-link.sub-link.mr-3(:to='`/property/${property_id}/customer/${document_id}${document_filter_postfix}/${$options.filters.encodeText(document.name)}`' exact)
          strong 조회
        router-link.sub-link.mr-3.link-add(:to='`/property/${property_id}/customer/${document_id}${document_filter_postfix}/${$options.filters.encodeText(document.name)}/start-from-scratch`')
          strong 추가
        router-link.sub-link.link-excel.mr-3(:to='`/property/${property_id}/customer/${document_id}${document_filter_postfix}/${$options.filters.encodeText(document.name)}/start-from-excel`')
          strong 엑셀로 입력하기
        //- template(v-if='saved_filter_id')
        //-   router-link.sub-link.mr-3(:to='`/property/${property_id}/customer/${document_id}${document_filter_postfix}/${$options.filters.encodeText(document.name)}/outreach`')
        //-     strong 필터로 메시지보내기
        //-       b-icon-lightning-fill.ml-1(style='color: #f1c40f')
        //- template(v-else)
        router-link.sub-link.mr-3(:to='`/property/${property_id}/customer/${document_id}${document_filter_postfix}/${$options.filters.encodeText(document.name)}/outreach`')
          strong 메시지보내기
        //- router-link.sub-link.mr-3(:to='`/property/${property_id}/customer/${document_id}/changelog`')
          strong 변경내역
        router-link.sub-link.mr-3(:to='`/property/${property_id}/customer/${document_id}${document_filter_postfix}/${$options.filters.encodeText(document.name)}/export`')
          strong 내보내기
        router-link.sub-link.mr-3(:to='`/property/${property_id}/customer/${document_id}${document_filter_postfix}/${$options.filters.encodeText(document.name)}/setting`')
          strong 설정
        //- a.link.mr-3(href='#') 변경내역
    //- router-view(@filter_updated='filter_updated' :key='`${document_id}.${record_id}`')
    router-view(@filter_updated='filter_updated' :key='`${document_id}.${saved_filter_id}`')

</template>

<script>

export default {
  name: 'index',
  props: ['property_id', 'document_id', 'record_id', 'saved_filter_id'],
  components: {

  },
  computed: {
    document_filter_postfix() {
      return this.saved_filter_id ? '-'+this.saved_filter_id : ''
    },
    is_starred() {
      if (this.$store.state.property?.menu_config?.blocks) {
        return this.$store.state.property.menu_config.blocks.filter(e => {
          return e.key == `d${this.document_id}:f${this.saved_filter_id || 0}`
        }).length > 0
      }
      return false
    }
  },
  watch: {
    '$store.state.documents'() {
      this.load()
    },
    'document_id'() {
      this.load()
    },
  },
  data() {
    return {
      done: false,
      adding: false,
      document: {},
      rows: [],
      fills: Array.from('00000000000000000000000'),

      saved_filter: {
        name: '',
      },
    }
  },
  async mounted() {
    if (this.$store.state.documents) {
      this.load()
    }
  },
  methods: {
    async toggle_star() {
      try {
        const menu_config = Object.assign({}, this.$store.state.property.menu_config)
        const key = `d${this.document_id}:f${this.saved_filter_id || 0}`
        if (this.is_starred) {
          menu_config.blocks = menu_config.blocks.filter(e => {
            return e.key != key
          })
        } else {
          let name
          if (this.saved_filter_id) {
            name = this.saved_filter.name
          } else {
            name = this.$store.state.documents_by_id[this.document_id].name
          }
          menu_config.blocks.unshift({
            ts: Date.now(),
            key,
            format: 'link',
            name,
            document_id: this.document_id,
            saved_filter_id: this.saved_filter_id,
          })
        }
        const r = await this.$http.put(`/v1/property/${this.property_id}/menu_config`, {
          menu_config,
        })
        if (r.data.message != 'ok') throw new Error(r.data.message || '즐겨찾기 저장 실패')
        await this.$store.dispatch('status flows', this.property_id)
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    filter_updated(filter) {
      this.saved_filter = filter
    },
    async load() {
      this.document = Object.assign({}, this.$store.state.documents_by_id[this.document_id])
      if (!this.document.id) {
        this.error = '해당 목록을 찾지 못했습니다.'
        return
      }

      this.done = true
    },
  },
}
</script>
